import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import headerImage from '@assets/services/enterprize-solution/enterprize-header.jpg';

const MethodWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepItem = styled.div`
  display: flex;
  height: 70px;
  font-weight: 600;
  font-size: 16px;
  span {
    width: 10px;
    height: 10px;
    background-color: ${props => props.theme.colors.primary.base};
    border-radius: 50%;
    position: relative;
    top: 5px;
    margin-right: 15px;
    display: flex;
    align-items: end;
    justify-content: center;

    &:not(.first):after {
      content: '';
      width: 2px;
      height: 70px;
      background-color: ${props => props.theme.colors.primary.base};
      display: flex;
      align-items: center;
    }
  }
`;

const StepWrapper = styled.div`
  width: fit-content;
  margin: 20px auto;
`;

const EnterpriseSolution = () => {
  return (
    <Layout>
      <Helmet title={' Enterprise Solutions'} />
      <PageHero image={headerImage} />
      <Heading title=" Enterprise Solutions" noHeadingIcon={true}>
        <p>
          Our team of experts is component enough to provide you with unique
          enterprise solutions. We understand the need-of-the-hour changes your
          firm needs like the sturdy support of technological advancement to
          stay contentious. Change is the only constant in this modern age and
          you have to keep up with the changes to win the market.
        </p>
        <p>
          Our customisable enterprise solutions are designed to blend multiple
          aspects of a corporation’s business through the trade of data from
          various business process areas and relevant databases. These solutions
          let firms to retrieve and distribute mission-critical data throughout
          the organization, providing the team with real-time operating
          information.
        </p>
        <p>
          We support our clients in reducing operational costs, extending
          capabilities, and sustaining the profitability through blending
          automation and our unique algorithms in their business methods. We
          strengthen their company’s existing information management system. We
          offer 360-degree solutions to ensure that they can handle complex
          business operations with ease.
        </p>
      </Heading>
      <MethodWrapper>
        <Container>
          <SectionHeading>
            How our methods can help you boost up your business
          </SectionHeading>
          <StepWrapper>
            <StepList>
              <StepItem>
                <span className="first" />
                Enhanced Operational Abilities
              </StepItem>
              <StepItem>
                <span />
                Increased Productivity and Flexibility
              </StepItem>
              <StepItem>
                <span />
                Improved Analysis and Reporting
              </StepItem>
              <StepItem>
                <span />
                Safe and Rapid Transactions
              </StepItem>
              <StepItem>
                <span />
                Seamless Communication
              </StepItem>
              <StepItem>
                <span/>
                Reduced Risks and Higher RoI
              </StepItem>
            </StepList>
          </StepWrapper>
        </Container>
      </MethodWrapper>
    </Layout>
  );
};

export default EnterpriseSolution;
